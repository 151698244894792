import React, { useContext, useState } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import { injected, bsc, walletconnect } from 'connectors'
import links from './config'
import useAuth from '../../hooks/useAuth'

const Menu: React.FC = props => {
  const { login } = useAuth();
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = useGetPriceData()
  const [connector,setConnector]= useState('')
  return (
    <UikitMenu
      links={links}
      priceLink="https://chartpro.benswap.finance/?token=0x8173dda13fd405e5bca84bd7f64e58caf4810a32"
      account={account as string}
      login={(connectorId: ConnectorId)=>{setConnector(connectorId);login(connectorId);}}
      // login={(connectorId: ConnectorId) => {
      //   if (connectorId === 'walletconnect') {
      //     return activate(walletconnect)
      //   }

      //   if (connectorId === 'bsc') {
      //     return activate(bsc)
      //   }

      //   return activate(injected)
      // }}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd}
      {...props}
    />
  )
}

export default Menu
