// Set of helper functions to facilitate wallet setup
import { UnsupportedChainIdError} from '@web3-react/core'
import { injected, bsc, walletconnect } from 'connectors'

// const NetWorkUrl = getNodeUrl()
/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const CHAINS = {
  56: {
    chainId: `0x38`,
    chainName: 'BSC',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: [`https://bscscan.com/`],
  }
}
export const switchChain = async (chainId) => {
  const provider = window.ethereum;
  if (provider && CHAINS[chainId]) {

    try {
      // chang ChainId
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: CHAINS[chainId].chainId }],
      });
      console.log('changChainId:', chainId);
      return true;
    } catch (error) {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [CHAINS[chainId]],
      })
      return true;
    }
  }
  return false;
}

export  const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    try {
      await switchChain(56);
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}
export  const connWallt = async (connectorId:string,activate:any) => {
  if (connectorId === 'walletconnect') {
    return activate(walletconnect)
  }
  
  if (connectorId === 'bsc') {
    return activate(bsc)
  }
  return activate(injected, async (error: Error) => {
    if (error instanceof UnsupportedChainIdError) {
      const hasSetup = await setupNetwork()
      if (hasSetup) {activate(injected)}
     }
    }
  )
}

export default connWallt;